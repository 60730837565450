<template>
  <main class="page page--home page--ads">
    <aside class="ad-banner ad-banner--left">
      <ins
        class="adsbygoogle"
        style="display: block"
        data-ad-client="ca-pub-1739040075681013"
        data-ad-slot="6619765797"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    </aside>

    <site-header />

    <section class="page--column-center">
      <aside class="ad-banner ad-banner--top">
        <ins
          class="adsbygoogle"
          style="display: inline-block; width: 728px"
          data-ad-client="ca-pub-1739040075681013"
          data-ad-slot="3069938390"
        ></ins>
      </aside>

      <div class="main-content" id="home-content">
        <h2>Will I have a snow day tomorrow?</h2>
        <h1>
          Snow Day Predictor calculates the chance of school being closed due to
          weather.
        </h1>

        <form class="location-input-wrap" @submit.prevent="handleFormSubmit">
          <input
            type="text"
            v-model="postal"
            :disabled="loading"
            placeholder="Enter ZIP or postal code..."
          />
          <button type="submit" :disabled="loading">Calculate</button>
        </form>

        <div class="help-text">
          Enter any ZIP Code in the United States or postal code in Canada.
        </div>
      </div>
    </section>
    <aside class="ad-banner ad-banner--right">
      <ins
        class="adsbygoogle"
        style="display: block"
        data-ad-client="ca-pub-1739040075681013"
        data-ad-slot="6619765797"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>

      <ins
        class="adsbygoogle"
        style="display: block"
        data-ad-client="ca-pub-1739040075681013"
        data-ad-slot="6619765797"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    </aside>
  </main>
</template>

<script>
export default {
  name: 'Home',
  head: {
    title: {
      inner: `Will I Have a Snow Day Tomorrow? Calculate Your Chance of a Snow Day`,
    },
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
    canSubmit() {
      return this.postal.trim().length > 0;
    },
  },
  data() {
    return {
      postal: '',
    };
  },
  mounted() {},
  methods: {
    async handleFormSubmit() {
      if (!this.canSubmit) return;

      this.$store.commit('loading', true);
      const resp = await this.$api(
        'GET',
        `/query/${encodeURIComponent(this.postal)}`
      );
      this.$store.commit('loading', false);

      if (resp.weekend === true) {
        this.$toast.warning(`It's the weekend! Try again on a weekday.`);
        return;
      }
      if (resp.error === true) {
        this.$toast.error(`An error has occurred, please try again.`);
        return;
      }

      //valid response
      this.$router.push({
        name: 'Result',
        params: {
          postalCode: this.postal,
          result: resp,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#home-content {
  max-width: 460px;
  margin: 50px auto;
  h2 {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  h1 {
    font-size: 20px;
    line-height: 1.5em;
    margin: 10px 0 0;
  }

  .location-input-wrap {
    margin: 35px auto 0;
    height: 54px;
    display: flex;
    background: #ffffff;
    border-radius: 100em;

    + .help-text {
      font-size: 12px;
      text-align: center;
      margin: 10px 0 0;
    }

    > input {
      appearance: none;
      border: none;
      outline: none;
      height: 50px;
      background: none;
      padding: 0 20px;
      flex: 1 1 auto;
      font-size: 18px;
      min-width: 0;
    }

    > button {
      height: 40px;
      border: none;
      appearance: none;
      background: linear-gradient(
        350deg,
        rgba(8, 127, 187, 1) 0%,
        rgba(0, 166, 251, 1) 100%
      );
      color: #ffffff;
      padding: 0 25px;
      outline: none;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 1px;
      text-transform: uppercase;
      border-radius: 100em;
      margin: 7px 7px 0 5px;
      flex: 0 0 auto;

      animation: blip 1s linear infinite;

      &:hover {
        background: linear-gradient(
          350deg,
          rgba(18, 152, 219, 1) 0%,
          rgba(35, 179, 252, 1) 100%
        );
      }

      &:active {
        background: linear-gradient(
          350deg,
          rgba(8, 127, 187, 1) 100%,
          rgba(0, 166, 251, 1) 0%
        );
      }

      &:disabled {
        background: linear-gradient(
          350deg,
          rgba(8, 127, 187, 0.5) 0%,
          rgba(0, 166, 251, 0.5) 100%
        );
      }
    }
  }
}

@keyframes blip {
  0% {
    box-shadow: rgba(0, 166, 251, 0) 0 0 0 0;
  }
  50% {
    box-shadow: rgba(0, 166, 251, 0.3) 0 0 0 3px;
  }
  100% {
    box-shadow: rgba(0, 166, 251, 0) 0 0 0 6px;
  }
}
</style>
